<template>
    <div class="columns is-multiline is-mobile">
        <div class="column is-4-tablet is-6-mobile is-3-desktop"
        v-for="item in cardLinks"
             v-if="$alfalab.showConfigPrinters(lb, item)"
        :key="item.name">
            <home-cards-item
                :data="item"
            ></home-cards-item>
        </div>
      <div class="column is-4-tablet is-6-mobile is-3-desktop"
           v-if="$alfalab.enableCaputureSarsResults(this)">
        <home-cards-item
          :data="{name: 'capture', icon: 'flask', color: 'is-primary', route: {name: 'capture'}, auth: ['NONONONO'], exact: false, onlySide: false}"
        ></home-cards-item>
      </div>
      <div class="column is-4-tablet is-6-mobile is-3-desktop"
           v-if="$alfalab.enableSchedule(this)">
        <home-cards-item
            :data="{name: 'schedule', icon: 'calendar-alt', color: 'is-google', route: {name: 'schedule'}, auth: ['1'], exact: false, onlySide: false}"
        ></home-cards-item>
      </div>

      <div class="column is-4-tablet is-6-mobile is-3-desktop"
           v-if="$alfalab.enabledOrdersByForeignWork(this)">
        <home-cards-item
            :data="{name: 'orders-by-foreign-work', icon: 'stethoscope', color: 'is-google', route: {name: 'orders-by-foreign-work'}, auth: ['1'], exact: false, onlySide: false}"
        ></home-cards-item>
      </div>

    </div>
</template>

<script>
    import HomeCardsItem from "./HomeCardsItem";
    export default {
        name: "HomeCards",
        components: {HomeCardsItem},
        computed: {
            cardLinks() {
                if(this.$auth.user().active_branch_office.toString() === '15'){
                  return this.$store.state.menu.linksGuardiaNacional || []
                }
                return this.$store.state.menu.links || []
            },
            lb(){
              return this;
            }
        }
    }
</script>

<style scoped>

</style>
